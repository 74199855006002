'use client'
import Slider from "react-slick";

export const SliderWrapper = ({settings, children}) => {

    return <Slider {...settings}>
        {children}
    </Slider>
}

