"use client";
import { useState } from "react";
import Image from "next/image";
import styles from "@/app/styles/components/common/faq.module.scss";
import SectionTitleIcon from "@/app/components/common/SectionTitleIcon";

const FAQ = ({ repeater, showTitle = false }) => {

    const [clickedElements, setClickedElements] = useState({});

    const handleExpandClick = (elementId) => {
        setClickedElements(prevState => ({
            ...prevState,
            [elementId]: !prevState[elementId]
        }));
    };

    return (
         <div className={`${styles.faqMain} padding-bottom`}>
            <div className="container">

                {
                    showTitle &&
                    <SectionTitleIcon title='<h2>FAQ</h2>' />
                }

                <div className={styles.faqContainer}>
                    {repeater.map((item) => (
                        <div className={styles.oneItem} key={item.id} onClick={() => handleExpandClick(`${item.id}`)}>
                            <p  className={`${styles.question} fw-500`}>
                                {item.question}
                            </p>
                            <div className={clickedElements[`${item.id}`] ? styles.show : styles.hide} dangerouslySetInnerHTML={{__html: item.answer }}>
                            </div>
                            <Image
                                src={'/arrow-right.png'}
                                alt="Strelica"
                                width={16}
                                height={16}
                                className={clickedElements[`${item.id}`] ? styles.arrowDown : styles.arrowRight} 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQ