"use client";
import {useState} from "react";
import "../../../styles/form.scss";
import styles from "@/app/styles/components/pages/home/form-newsletter.module.scss";
import Image from "next/image";
import {isValidEmail} from "@/app/utils/Validations";
import {addContact} from "@/app/services/SendGridService";
import {FormattedMessage} from "react-intl";

const FormNewsletter = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('')


    const handleNewsletterClick = (email) => {
        addContact({
            email: email
        }).then(response => {
            setMessage('components.pages.home.formNewsletter')

            setTimeout(() => {
                setMessage('')
            }, 3000)
        })
    }

    return (

        <div className={styles.form}>

            {
                message &&
                <div className={styles.message}>
                    <p>
                        <FormattedMessage id={message}/>
                    </p>
                </div>
            }

            <div className={styles.inputWrapper}>
                <Image
                    src={'/email.png'}
                    alt="Email"
                    width={16}
                    height={14}
                />
                <input
                    className={styles.inputEmail}
                    id="home-email-input-newsletter"
                    type="email"
                    value={email}
                    placeholder="Unesi email"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <input
                onClick={() => handleNewsletterClick(email)}
                type="submit"
                value="Prijavi se"
                className={`${isValidEmail(email) ? styles.submitBtn : styles.submitBtnDisabled} btnGlobal`}
            />
        </div>


    );
};

export default FormNewsletter;
