"use client"
import styles from '@/app/styles/components/pages/shop/product-preview.module.scss'
import Link from "next/link";
import CustomNumberFormat from "@/app/components/common/CustomNumberFormat";
import {FormattedMessage} from "react-intl";
import {addToCart} from "@/app/services/CartService";
import {updateCart} from "@/app/slices/CartSlice";
import {useDispatch} from "react-redux";
import {getCDNImage} from "@/app/utils/ImageUtil";
import Image from "next/image";

const ProductPreview = ({product}) => {
    const dispatch = useDispatch()

    const image = product.main_image || (product.images[0] ? product.images[0].src : '/pzn-transparent.png');

    const handleAddToCart = (product) => {
        addToCart({
            [product.id]: 1
        }).then(response => {
            dispatch(updateCart({data: response, uuid: 'product preview'}))
        })
    }

    return <div className={styles.productPreview}>
        <Link href={'/proizvod/' + product.slug}>
            <div className={styles.image}>
                <Image src={getCDNImage(image)} alt={product.name + ' - Slika 1'}
                       fill
                       quality={80}
                />
            </div>
        </Link>
        <div className={styles.content}>
            <Link href={'/proizvod/' + product.slug}>
                <h3>{product.name}</h3>
            </Link>

            <div className={styles.priceContainer}>
                {
                    product.regular_price > product.price &&
                    <span className={styles.regularPrice}>
                        <CustomNumberFormat value={parseFloat(product.regular_price).toFixed(2)}/>
                    </span>
                }

                <span className={styles.price}>
                    <CustomNumberFormat value={parseFloat(product.price).toFixed(2)}/>
                </span>
            </div>

            <button className={'btn btn-secondary'} onClick={() => handleAddToCart(product)}>
                <FormattedMessage id={'component.pages.shop.productPreview.addToCart'}/>
            </button>
        </div>

    </div>
}

export default ProductPreview
