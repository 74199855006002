export const isEmpty = value => {
    return value === null || value === undefined || value === '';
}

export const isValidEmail = value => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(value);
}

export const isValidWebsite = value => {
    const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}(\/\S*)?$/;
    return websiteRegex.test(value);
}
export const isEmptyObject = (obj) => Object.keys(obj).length === 0;
