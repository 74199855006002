import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";

export const addContact = async (contact) => {

    const data = {
        list_ids: [process.env.NEXT_PUBLIC_SENDGRID_NEWSLETTER_LIST],
        contacts: [contact]
    }

    const result = await fetch(`${process.env.NEXT_PUBLIC_SENDGRID_API_URL}/v3/marketing/contacts`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.NEXT_PUBLIC_SENDGRID_API_KEY}`
        },
        body: JSON.stringify(data)
    })
    return await result.json()
}