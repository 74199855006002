import Image from "next/image";
import styles from "@/app/styles/components/common/section-title-icon.module.scss";
import {getCDNImage} from "@/app/utils/ImageUtil";
    
const SectionTitleIcon = ({ title, icon }) => {
    return (
        <div className={styles.titleIcon}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></div>
            {
                icon &&
                <Image
                    src={getCDNImage(icon)}
                    alt="Poklončić"
                    width={53}
                    height={55}
                    className={styles.icon}
                />
            }

        </div>
    )
}

export default SectionTitleIcon
